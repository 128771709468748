import React, { KeyboardEvent, ChangeEvent, useState, useEffect, useLayoutEffect, TextareaHTMLAttributes, InputHTMLAttributes, useRef, MutableRefObject } from 'react';
import './App.css';
import Board from './Board';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import  { Script } from './Models'
import { BackendApi, Session } from './Net';
import { ScriptApi } from './Controllers';
import Outline from './Outline';
import { PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import Settings from './Settings';
import { DetailedHTMLProps } from 'react';
import { Aidraft } from './Aidraft';

interface Props extends RouteComponentProps<any> {
  scriptId: string;
  session: Session,
  scriptApiProvider: (scriptId: string) => ScriptApi,
  isLocal: boolean,
  signInTrigger: (successHandler: (api: BackendApi)=>void) => void;
  clearLocalScript: () => void;
  mainToolbarRef: React.RefObject<HTMLDivElement>;
}

function sanitizePathName(pathname: string) {
  const n = pathname.indexOf('/script/');
  if (n >= 0) {
    const start = n + '/script/'.length;
    const end = pathname.indexOf('/', start);
    if (pathname.substring(start, end > 0 ? end : pathname.length) !== 'new') {
      return pathname.substring(0, start) + '*' + (end > 0 ? pathname.substring(end) : '');
    }
  }
  return pathname;
} 

function EditableInput(props: {
  multiLine: boolean, 
  initialValue: string, 
  saveFn: (value: string) => void, 
  confirmMsg: string,
  placeholder?: string,
  className?: string,
  name?: string
}) {
  const [editField, setEditField] = useState<string>();
  const ref = useRef<any>();
  useEffect(() => {
    if (editField == null) {
      ref.current!.blur();
    }
  }, [editField]);
  const inputProps = {
    placeholder: props.placeholder,
    name: props.name,
    className: props.className,
    value: editField != null ? editField : props.initialValue,
    onChange: (event: ChangeEvent<any>) => {setEditField(event.currentTarget.value);},
    onKeyDown: (event: KeyboardEvent<any>) => {
      if (event.key === "Enter") {
        props.saveFn(event.currentTarget.value);
        setEditField(undefined);
      }
      if (event.key === "Escape") {
        setEditField(undefined);
      }
    }, 
    onBlur: (event: React.FocusEvent<any>) => { 
      if (editField != null) { 
        if (window.confirm(props.confirmMsg)) {
          props.saveFn(editField);
        }
        setEditField(undefined); 
      }
    }
  };
  return props.multiLine ? 
    <textarea ref={ref} {...inputProps} /> : 
    <input ref={ref} {...inputProps} />;
}

function App(props: Props) {
  const [script, setScript] = useState<Script>();
  const [scriptApi, setScriptApi] = useState<ScriptApi>();
  const [isBoard, setIsBoard] = useState<'board' | 'outline' | 'aidraft'>('board');
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [boardFitScreen, setBoardFitScreen] = useState(false);
  const newScene = () => {
    scriptApi!.newScene(Object.keys(script!.board.scenes).length + 1);
  };
  const saveScript = () => {
    const newScriptOp = (api: BackendApi) => {
      api.newScript((scriptId: string) => {
        if (scriptId != null) {
          props.clearLocalScript();
          props.history.push('/script/' + scriptId);
        }
      },
      script)
    };
    if (props.session.isLoggedIn) {
      newScriptOp(props.session.api);
    } else {
      props.signInTrigger((api) => {
        newScriptOp(api);
      });
    }
  }
  useEffect(() => {
      const api = props.scriptApiProvider(props.scriptId);
      api.subscribe((s: Script) => {
        setScript(JSON.parse(JSON.stringify(s)));
      });
      setScriptApi(api);
  }, [props.scriptId]);
  const orientationChangeHandler = () => {
    const oneoff = function() {
      window.removeEventListener("resize", oneoff);
    };
    window.addEventListener("resize", oneoff);
  };
  useLayoutEffect(() => {
    window.addEventListener('orientationchange', orientationChangeHandler);
    return () => {
      window.removeEventListener('orientationchange', orientationChangeHandler);
    };
  }, [])
  return (
        <div className="App" style={{cursor: 'pointer'}}>
            <EditableInput multiLine={false} name="title" className="TitleField" placeholder="Script title" 
              initialValue={(script && script.title) || ''}
              saveFn={saveEditedTitle}
              confirmMsg={"Keep modified title?"}
              />
            <div className="d-flex LogLineRow">
              <EditableInput multiLine={true} name="description" className="LogLineField"
              placeholder="Log line, a sentence describing what your script is about " 
              initialValue={(script && script.description) || ''}
              confirmMsg={"Keep modified description?"}
              saveFn={saveEditedDescription}
              />
              <div className="d-flex flex-row align-items-center justify-content-end" style={{flexShrink: 0}}>
                <i id="board-settings-toggle" className="fas fa-cog p-3" style={{cursor: 'pointer'}}/>
                <span className="nav">
                  <span className="nav-item">
                    <span className={"nav-link p-1 " + (isBoard === 'board' ? "text-white" : "")} onClick={() => {
                      setIsBoard('board');
                      }}>Board</span>
                  </span>
                  <span className="nav-item">
                    <span className={"nav-link p-1 " + (isBoard === 'outline' ? "text-white" : "" )} onClick={() => {
                      setIsBoard('outline');
                      setSettingsOpen(false);
                    }}>Outline</span>
                  </span>
                  {script?.aidraft != null ? 
                  <span className="nav-item">
                    <span className={"nav-link p-1 " + (isBoard === 'aidraft'  ? "text-white" : "")} onClick={() => {
                      setIsBoard('aidraft');
                      setSettingsOpen(false);
                    }}>AI Draft</span> 
                  </span> :
                    <></>}
                </span>
                <span>{(props.isLocal ? <button type="button" onClick={saveScript} className="btn btn-primary btn-sm mr-1">Save</button> : '')}
                <button type="button" className="btn btn-primary btn-sm" onClick={newScene}>New Scene</button></span>
              </div>
            </div>
            {scriptApi ? 
              { 'board': <Board className="Cards" fitHeight={boardFitScreen}
                  cards={(script ? {...script.board.scenes} : {})} api={scriptApi}
                  numRows={script && script.board.acts}
                  mainToolbarRef={props.mainToolbarRef}/>,
                'outline': <Outline api={scriptApi} scenes={(script ? {...script.board.scenes} : {})} acts={script && script.board.acts}
                mainToolbarRef={props.mainToolbarRef}></Outline>,
                'aidraft': <Aidraft draft={script?.aidraft || ''}/>
                }[isBoard]
                : ''}
            {script != undefined && scriptApi != undefined ? 
              <Settings isOpen={settingsOpen} 
                toggleOpen={() => {
                  setSettingsOpen(!settingsOpen);
                }}
                script={script} 
                api={scriptApi} 
                boardFitScreen={boardFitScreen} 
                setBoardFitScreen={setBoardFitScreen} />
               : <></>}
        </div>);

  function saveEditedTitle(title: string) {
    scriptApi!.editScript(title, null);
  }

  function saveEditedDescription(description: string) {
    scriptApi!.editScript(null, description);
  }
}

export default withRouter(App);
