import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps<any> {
    redirectTo?: string;
}

class Redirecter extends React.Component<Props, any> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        if (this.props.redirectTo) {
            this.props.history.push(this.props.redirectTo);
        }
    }

    render() {
        return '';
    }
}

export default withRouter(Redirecter);