import React, { RefObject, Touch, CSSProperties, useState, useMemo, useRef, useEffect } from 'react';
import Card from './Card';
import './Board.css';
import { Scene } from './Models';
import { ScriptApi } from './Controllers';
import {Spring} from 'react-spring/renderprops'

interface Props {
    api: ScriptApi;
    numColumns?: number;
    numRows?: number;
    fitHeight: boolean;
    cards: Record<string, Scene>;
    rowHeight?: number;
    showActs?: boolean;
    onClickOverride?: (id: string) => void; // Disables regular zoom behavior,
    mainToolbarRef?: React.RefObject<HTMLDivElement>;
}

function computeTransformValue(scale: number, translateX: number, translateY: number): string {
    return "matrix(" + scale + ", 0, 0, " +  scale + "," + translateX + ", " + translateY + ")";
}

const cardDim = {
    width: 900,
    height: 500,
    spacingX: 10,
    spacingY: 250 
};

interface ViewTransform {
    s: number;
    x: number;
    y: number;
}

interface BoardDim {
    numRows: number;
    numColumns: number;
    width: number;
    height: number;
    positionRegionWidth: number;
    positionRegionHeight: number;
    leftLegendWidth: number;
}

const findParentCard = (target: HTMLElement) => {
    let element: HTMLElement | null = target;
    while (element) {
        if (element.hasAttribute("card-id")) {
            return element;
        }
        element = element.parentElement;
    }
    return null;
};

function InnerCards(props: {
    transform: ViewTransform;
    boardDim: BoardDim;
    zoomCard?: {left: number, top: number, id: string};
    api: ScriptApi;
    cards: Record<string, Scene>;
    boardDiv: RefObject<HTMLDivElement>;
    setMovingCard: (isMoving: boolean) => void;
    zoomInOnCard: (card: {left: number, top: number, id: string}) => void;
    clearZoomCard: () => void;
    showActs: boolean;
    onClickOverride?: (id: string) => void;
    mainToolbarRef?: RefObject<HTMLDivElement>;
}) {
    const movedCard = useRef<Scene>();
    const [movingUpdater, setMovingUpdater] = useState<object>(); // Updates the memos to read from the ref

    const getCard = (id: string) => {
        return (movedCard.current != null) && movedCard.current.id === id ? 
            movedCard.current : props.cards[id];
    }

    const mouseTouchDown = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>, isTouch?: boolean) => {
        let data: React.MouseEvent<HTMLElement> | Touch;
        if (isTouch) {            
        //    if ((window.innerHeight < 500 || window.innerWidth < 500) &&
        //     (event as React.TouchEvent<HTMLElement>).touches.length != 1) {
        //        return; // don't interfer with pinch-zooms
        //    }
            data = (event as React.TouchEvent<HTMLElement>).touches[0];
        } else {
            data = event as React.MouseEvent<HTMLElement>
        }
        let element = findParentCard(data.target as HTMLElement);
        if (!element) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        let startX = data.pageX;
        let startY = data.pageY;
        let startTop = element.offsetTop;
        let startLeft = element.offsetLeft;
        let endfn = (event: MouseEvent | TouchEvent) => {
            if (isTouch) {
                props.boardDiv.current!.removeEventListener("touchmove", movefn);
                document.removeEventListener("touchend",  endfn);
            } else {
                document.onmouseup = null;
                document.onmousemove = null;
            }
            if (movedCard.current != null) {
                props.api.moveScene(movedCard.current.id!, movedCard.current.x, movedCard.current.y);
                movedCard.current = undefined;
                props.setMovingCard(false);
                setMovingUpdater(undefined);
            } else {
                props.zoomInOnCard({left: element!.offsetLeft, top: element!.offsetTop, id: element!.getAttribute("card-id")!});
            }
        };
        const movefn = (event: MouseEvent | TouchEvent) => {
            let data;
            if (isTouch) {
                event.preventDefault();
                event.stopPropagation();
                data = (event as TouchEvent).touches[0];
            } else {
                data = (event as MouseEvent);
            }
            const dx = data.pageX - startX;
            const dy = data.pageY - startY;
            const y = startTop + (dy / props.transform.s);
            const x = startLeft + (dx / props.transform.s)
            const cardLeft = Math.max(props.boardDim.leftLegendWidth, Math.min(props.boardDim.positionRegionWidth, x)) - props.boardDim.leftLegendWidth;
            const cardTop = Math.max(0, Math.min(props.boardDim.positionRegionHeight, y));
            const cardId = element!.getAttribute("card-id");
            if (cardId != null) {
                const card = getCard(cardId);
                const cardCopy = Object.assign({}, card);
                cardCopy.id = cardId;
                cardCopy.x = cardLeft / props.boardDim.width;
                cardCopy.y = cardTop / props.boardDim.height;
                movedCard.current = cardCopy;
                setMovingUpdater({});
                props.setMovingCard(true);
            } 
        };
        if (isTouch) {
            props.boardDiv.current!.addEventListener("touchmove", movefn, {passive: false});
            document.addEventListener("touchend",  endfn);
        } else {
            document.onmouseup = endfn;
            document.onmousemove = movefn;
        }
    };

    const rows = useMemo(() => {
        if (!props.showActs) {
            return <></>;
        }
        let rows: any[] = [];
        for (let i = 0; i < props.boardDim.numRows; i++) {
            const rowHeight = props.boardDim.height / props.boardDim.numRows;
            const rowPos = {
                top: i*rowHeight,
                left: 0,
                width: props.boardDim.width,
                height: rowHeight,
                background: "inherit"
            };
            const mc = movedCard.current;
            const hasMoveCard = (mc != null) && 
                mc.y * props.boardDim.height >= rowPos.top && 
                mc.y * props.boardDim.height < (rowPos.top + rowPos.height);
            rowPos["background"] = hasMoveCard ? "#aaccff" : "inherit";

            rows.push((<div key={i} className="RowLine" style={rowPos}><div className="h1 ActHeader">Act {i+1}</div></div>))
        }
        return rows;
    }, [props.boardDim, movingUpdater, props.showActs]);

    const cardKeys = useMemo(() => {
        return Object.keys(props.cards);
    }, [props.cards])

    const cardElements = useMemo(() => {
        return cardKeys.map((id) => {
            const c = getCard(id);
            return (<div key={id} 
            card-id={id}
            style={{
                position: "absolute", 
                left: ((Math.min(c.x * props.boardDim.width, props.boardDim.width - cardDim.width) || 0) + props.boardDim.leftLegendWidth) + "px", 
                top: (Math.min(c.y * props.boardDim.height, props.boardDim.height - cardDim.height) || 0) + "px",
                height: cardDim.height + "px",
                maxHeight: cardDim.height + "px",
                display: 'flex',
                alignItems: 'stretch'
            }}
            onClick={props.onClickOverride ? () => {
                props.onClickOverride?.(id);
            } : undefined}
            onMouseDown={props.onClickOverride || props.zoomCard ? undefined : mouseTouchDown} 
            onTouchStart={props.onClickOverride || props.zoomCard ? undefined : (event) => mouseTouchDown(event, true)}>
                <Card api={props.api}
                    scene={c}
                    isEditing={props.zoomCard && props.zoomCard.id === id}
                    cardId={id}
                    closeListener={props.clearZoomCard}
                    cardWidth={cardDim.width}
                    cardHeight={cardDim.height}
                    sceneCount={cardKeys.length}
                    mainToolbarRef={props.mainToolbarRef}
                    showOverlay={true}
                />
            </div>)});
    }, [props.cards, props.zoomCard, props.boardDim, movingUpdater]);

    return <div style={{
                transform: computeTransformValue(props.transform.s, props.transform.x, props.transform.y),
                transformOrigin: "0 0",
                width: props.boardDim.width + "px",
                height: props.boardDim.height + "px",
                //touchAction: 'none'
            }}>
            {rows}
            {
                cardElements
            }
        </div>;
}

function Board(props: Props & React.HTMLAttributes<HTMLDivElement>) {
    const [transform, setTransform] = useState<ViewTransform>({x: 0, y: 0, s: 1});
    const [oldTransform, setOldTransform] = useState<ViewTransform>();
    const [zoomCard, setZoomCard] = useState<{left: number, top: number, id: string}>();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [isMovingCard, setMovingCard] = useState(false);

    const boardDiv = useRef<HTMLDivElement>(null);

    const boardDim: BoardDim = useMemo(() => {
            const cardsX = props.numColumns || 6;
            const cardsY = props.numRows || 4;
            const leftLegendWidth = props.showActs ?? true ? 100 : 0;
            const boardWidth = leftLegendWidth + cardsX * (cardDim.width + 2 * cardDim.spacingX);
            const boardHeight = cardsY * (cardDim.height * (props.rowHeight ?? 2));
            return {
                numRows: cardsY,
                numColumns: cardsX,
                width: boardWidth,
                height: boardHeight,
                positionRegionWidth: boardWidth - cardDim.width,
                positionRegionHeight: boardHeight - cardDim.height,
                leftLegendWidth
            };
    }, [props.numColumns, props.numRows, props.showActs]); 

    const boardContainerStyle: CSSProperties = useMemo(() => {
        const w = (Math.ceil(boardDim.width * transform.s)) + 'px';
        const h = (Math.ceil(boardDim.height * transform.s)) + 'px';
        return {
            overflow: "hidden", 
            maxWidth: "100%", 
            maxHeight: h,
            width: w, 
            height: h,
            cursor: 'pointer',
        };
    }, [boardDim, transform]);

    useEffect(() => {
        if (boardDiv.current != null) {
            const widthOnScreen = boardDiv.current.parentElement!.offsetWidth;
            const heightOnScreen = window.innerHeight - boardDiv.current.parentElement!.offsetTop;
            if (zoomCard != null) {
                let margin = 32; //Math.min(window.innerWidth, window.innerHeight) < 575 ? 40 : 16;
                let s = Math.min(widthOnScreen / (cardDim.width + 2 * margin), 
                    heightOnScreen / (cardDim.height + 2 * margin), 1);
                s = Math.min(800/(cardDim.width + 2 * margin), s); // Limits size on larger screens
                // Center 
                let marginX = (widthOnScreen - cardDim.width*s) / 2;
                let leftShift = window.matchMedia("(min-width: 992px)").matches ? 215 : 0; // Moves the zoomed card left to fit side details view
                const t = {s, x: - (zoomCard.left * s - marginX + leftShift), y: - ((zoomCard.top - window.scrollY )* s - margin)};
                setTransform(t);          
            } else {
                const widthScale = widthOnScreen / boardDim.width;
                const heightScale = heightOnScreen / boardDim.height;
                setTransform({x: 0, y: 0, s: !props.fitHeight ? widthScale : Math.min(widthScale, heightScale)});
            }
        }
    }, [props.fitHeight, zoomCard, boardDim, windowWidth, windowHeight]);

    const orientationChangeHandler = () => {
        const oneoff = function() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
            window.removeEventListener("resize", oneoff);
        };
        window.addEventListener("resize", oneoff);
    }

    const preventScroller = (event: TouchEvent) => {
        // if ((window.innerHeight < 500 || window.innerWidth < 500) && event.touches.length != 1) {
        //     return; // don't interfer with pinch-zooms on small screens
        // }
        const touch = event.touches[0];
        if (boardDiv.current != null && isMovingCard && !zoomCard) {
            const rect = boardDiv.current.getBoundingClientRect();
            const x = touch.pageX;
            const y = touch.pageY;
            if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
                event.preventDefault();
            }     
        }
    };

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
        window.addEventListener("touchmove", preventScroller, {passive: false});
        window.addEventListener("orientationchange", orientationChangeHandler); 
        return () => {
            window.removeEventListener("touchmove", preventScroller);
            window.removeEventListener("orientationchange", orientationChangeHandler);
        }
    }, [])
    
    const clearZoomCard = () => {
        if (zoomCard != null) {
            setOldTransform ({ ...transform });
            setZoomCard(undefined);
        }
    }

    const zoomInOnCard = (target: {left: number, top: number, id: string}) => {
        setOldTransform ({ ...transform });
        setZoomCard(target);
    }
   
    return (
        <div 
        style={boardContainerStyle} 
        ref={boardDiv}>
        {oldTransform ?
            <Spring
                from={oldTransform}
                to={transform}
                immediate={!oldTransform} config={{duration: 200}}
                onRest={() => {
                    setOldTransform(undefined);
                }}>
                {
                    props2 => <InnerCards transform={props2} 
                            boardDim={boardDim} 
                            zoomCard={zoomCard}
                            api={props.api}
                            cards={props.cards}
                            boardDiv={boardDiv}
                            zoomInOnCard={zoomInOnCard}
                            clearZoomCard={clearZoomCard}
                            setMovingCard={setMovingCard}
                            onClickOverride={props.onClickOverride}
                            showActs={props.showActs ?? true}
                            mainToolbarRef={props.mainToolbarRef}
                        />
                }
        </Spring> :
            <InnerCards transform={transform} 
                boardDim={boardDim} 
                zoomCard={zoomCard}
                api={props.api}
                cards={props.cards}
                boardDiv={boardDiv}
                zoomInOnCard={zoomInOnCard}
                clearZoomCard={clearZoomCard}
                setMovingCard={setMovingCard}
                onClickOverride={props.onClickOverride}
                showActs={props.showActs ?? true}
                mainToolbarRef={props.mainToolbarRef}
            />
        }
        </div>
    );
}

export default Board;