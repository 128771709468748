import React, { useState, useEffect, useRef, useMemo, MutableRefObject } from "react";
import { Scene } from "./Models";
import "./Outline.css"
import { ScriptApi } from "./Controllers";
import { withRouter, RouteComponentProps } from "react-router";
import Card from "./Card";
import ReactDOM from "react-dom";

interface Props extends RouteComponentProps<any> {
    scenes: Record<string, Scene>;
    api: ScriptApi;
    acts?: number;
    mainToolbarRef: React.RefObject<HTMLDivElement>;
}

export function orderScenes(scenes: Record<string, Scene>, actCount?: number) {
    const acts = actCount || 4;
    const getAct = (y: number) => { return Math.floor(y * acts) + 1; };
    const allScenes = Object.entries(scenes).sort((a: [string, Scene], b: [string, Scene]) => {
        // assume x is never less than 1/10000 unless it's 0
        const vala = getAct(a[1].y)*100000 + a[1].x*10000 + a[1].y;
        const valb = getAct(b[1].y)*100000 + b[1].x*10000 + b[1].y;
        return vala - valb;
    });
    const order: [string, number][][] = [];
    for (let i: number = 0; i < acts; i++) {
        order[i] = allScenes
            .map(([id, scene], idx) => { return {id: id, act: getAct(scene.y), globalIdx: idx}; })
            .filter(({act}) => { return act === i + 1; })
            .map(({id, globalIdx}) => { return [id, globalIdx]; });
    }
    return order;
}

function OrderedCards(props: {
    order: [string, number][][];
    api: ScriptApi;
    scenes: Record<string, Scene>;
    selectedId?: string;
    select?: (id: string | undefined) => void;
    mainToolbarRef: React.RefObject<HTMLDivElement>;
    }) {
    const sceneCount = Object.keys(props.scenes).length;
    if (props.select == null) {
        const cards = props.order.flatMap((ids, idx) => { 
            return ids.map(([id, idx]) => {
                return [id, <Card 
                    scene={props.scenes[id]}
                    api={props.api} 
                    cardId={id} 
                    closeListener={() => {}} 
                    cardWidth={900} 
                    isEditing={props.selectedId === id }
                    sceneCount={sceneCount}
                    mainToolbarRef={props.mainToolbarRef}/>
                ]
            });
        });
        const cardsPerPage = 2;
        const pages = [];
        while (pages.length * cardsPerPage < cards.length) {
            pages.push(<div className="page" key={'page-' + pages.length}>
                {cards
                    .slice(pages.length * cardsPerPage, Math.min(cards.length, (pages.length + 1) * cardsPerPage))
                    .map(([id, card]) => {
                        return <div key={'card-'+id} style={{display: 'flex', alignItems: 'stretch', minHeight: '500px', marginBottom: '50px', position: 'relative'}}>
                        {card}
                        </div>;
                    })}
            </div>);
        }
        return <>
            {pages.map(p => {
                return p;
            })}
        </>
    } else {
        return <>
            {props.order.length === 0 ? <p>No scenes</p> :
            props.order.map((ids, idx) => {
                return <div key={'act-'+idx} className="d-flex flex-column p-0 align-items-stretch flex-grow-1">
                    {ids.length > 0 ? <h3 className="align-self-start">{'Act ' + (idx + 1)}</h3> : ''}
                    {  
                        ids.map(([id, idx]) => {
                            return (
                                <div key={'outline-' + id} className="d-flex flex-column align-items-stretch flex-grow-1">
                                    <h5 className="align-self-start pb-0 mb-0 mt-3">{idx + 1}.</h5>
                                    <div style={{display: 'flex', alignItems: 'stretch', minHeight: '200px'}}
                                        onClick={() => { if (!props.selectedId) { 
                                            props.select!(id);
                                        }}}>
                                    <Card 
                                        scene={props.scenes[id]}
                                        api={props.api} 
                                        cardId={id} 
                                        closeListener={() => { 
                                            props.select!(undefined);
                                        }} 
                                        cardWidth={900} 
                                        isEditing={props.selectedId === id }
                                        sceneCount={sceneCount}
                                        mainToolbarRef={props.mainToolbarRef} />
                                    </div>
                                </div>);
                        })
                        }
                </div>
            })}</>;
    }
}

const PrintPortal = (props: {
    children: JSX.Element,
    container: HTMLDivElement
}) => {
    return ReactDOM.createPortal(props.children,props.container);
};

function Outline(props: Props) {
    const [editingId, setEditingId] = useState<string>();
    const outlineTop: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;
    const [outlineScreenWidth, setOutlineScreenWidth] = useState<number>();

    const orientationChangeHandler = () => {
        const oneoff = function() {
            setOutlineScreenWidth(outlineTop.current!.clientWidth);
            window.removeEventListener("resize", oneoff);
        };
        window.addEventListener("resize", oneoff);
    };

    useEffect(() => {
        window.addEventListener('orientationchange', orientationChangeHandler);
        return () => {
            window.removeEventListener('orientationchange', orientationChangeHandler);
        };
    }, []);

    const order = useMemo(() =>  {
        return orderScenes(props.scenes, props.acts);
    }, [props.scenes, props.acts]);

    const [portalVisible, setPortalVisible] = useState<{window: Window, container: HTMLDivElement}>();
    const scaleStyle = useMemo(() => {
        if (!outlineScreenWidth) {
            return {}
        };
        const outlineInnerWidth = 960;
        if (outlineInnerWidth > outlineScreenWidth) {
            const scale = outlineScreenWidth / outlineInnerWidth;
            return {
                width: (scale * outlineScreenWidth) + 'px',
                maxWidth: (scale * outlineScreenWidth) + 'px',
                transform: 'scale(' + scale + ')',
                transformOrigin: 'top left'
            };
        }
        return {
            maxWidth: outlineInnerWidth + 'px'
        };
    }, [outlineScreenWidth]);
    return <><div className="d-flex flex-column flex-grow-1 OutlineTop">
            <div className="d-flex flex-column align-items-stretch bg-white p-3 flex-grow-1" 
                style={{width: '100%', 
                maxWidth: window.matchMedia("(min-width: 992px)").matches ? '788px': '638px', 
                position: 'relative'}} 
            ref={(node) => {
                outlineTop.current = node;
                if (node != null) {
                    setOutlineScreenWidth(outlineTop.current!.clientWidth);
                }
            }}>
                <div id="OutlineScaler" className="d-flex flex-column align-items-stretch" style={scaleStyle}>
                    <span className="h2 align-self-start">Outline</span>
                    <OrderedCards selectedId={editingId} api={props.api} scenes={props.scenes} order={order} 
                        select={(id: string | undefined) => {
                            setEditingId(id);
                        }}
                        mainToolbarRef={props.mainToolbarRef}
                        />
                </div>
                <button type="button" className="btn btn-primary btn-sm"
                    style={{position: 'absolute', right: 5, top: 5}}
                    onClick={() => {
                        let externalWindow;
                        if (portalVisible && !portalVisible.window.closed) {
                            portalVisible.window.focus();
                            externalWindow = portalVisible.window;
                            externalWindow!.document.body.innerHTML = '';
                        } else {
                            externalWindow = window.open('', '', 'width=920,height=600,left=200,top=200');
                            const meta = externalWindow!.document.createElement('meta');
                            meta.setAttribute('name', 'viewport');
                            meta.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=yes');
                            externalWindow!.document.head.appendChild(meta);
                        }
                        const dialogContent = externalWindow!.document.createElement('div');
                        dialogContent.setAttribute('id', 'top-div')
                        dialogContent.style['position'] = 'relative';
                        externalWindow!.document.body.appendChild(dialogContent);
                        externalWindow!.onclose = () => {
                            setPortalVisible(undefined);
                        };
                        setPortalVisible({ window: externalWindow!, container: dialogContent});
                    }}
                >Print/Export</button>
            </div>
        </div>
        {portalVisible == null ? <></>:
            <PrintPortal container={portalVisible.container}>
                <>
                <OrderedCards api={props.api} scenes={props.scenes} order={order} mainToolbarRef={props.mainToolbarRef}/>
                <style dangerouslySetInnerHTML={{__html: `
                    body {
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
                        position: relative;
                    }

                    #top-div {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        display: block;
                    }

                    .Editor {
                        font-size: 38pt;
                        font-weight: 500;
                        line-height: 90%;
                        color: black;
                        flex-grow: 1;
                        white-space: pre-wrap;
                    }

                    strong {
                        font-weight: 800;
                    }

                    p {
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    .page {
                        position: relative;
                        page-break-inside: avoid;
                        page-break-after: always;
                        min-height: 100vh;
                    }
                `}}/>
                </>
            </PrintPortal>
        }
        </>;
}

export default withRouter(Outline);