import React, { ChangeEvent, useState, useEffect } from 'react';
import {
    Link, RouteComponentProps, withRouter
  } from 'react-router-dom';
import { Session } from './Net';
import { Script } from './Models';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

interface Props extends RouteComponentProps<any> {
    session: Session;
    localScript?: Script;
    reloadListener: () => void;
}

function Profile(props: Props) {
    const [scripts, setScripts] = useState<Record<string, string>>({});
    const [newEmail, setNewEmail] = useState<string>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>();
    const [isChangeEmailConfirm, setIsChangeEmailConfirm] = useState<boolean>();
    const [changeEmailError, setChangeEmailError] = useState<string>();

    const updateScriptList = () => {
        if (props.session.isLoggedIn) {
            props.session.api.getAllScripts((scriptIdsToTitles) => {
                setScripts(scriptIdsToTitles);
            })
        }
    }

    useEffect(() => {
        updateScriptList();
    }, []);
    useEffect(() => {
    }, [props.location]);
    useEffect(() => {
        updateScriptList();
    }, [props.session.isLoggedIn]);

    const newScript = () => {
        if (props.session.isLoggedIn) {
            props.session.api.newScript((id) => {
                updateScriptList();
            });
        }
    }

    const updateNewsConsent = (event: ChangeEvent<HTMLInputElement>) => {
        if (props.session.isLoggedIn) {
            props.session.api.updateUser({
                newsConsent: event.currentTarget.checked
            },
            () => {
                props.reloadListener()
            });
        }
    }

    const signOut = () => {
        if (props.session.isLoggedIn) {
            props.session.api.signOut(() => {window.location.href = '/'});
        }
    }

    const deleteAccount = () => {
        if (props.session.isLoggedIn) {
            const passwd = prompt('Deleting your account will delete all your data. Enter your password to continue');
            if (passwd) {
                props.session.api.deleteAccount(passwd, () => {window.location.href = '/'});
            }
        }
    }

    const isEmailValid = () => {
        return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newEmail || ''));
    };

    const changeEmailAddress = () => {
        if (props.session.isLoggedIn && newEmail) {
            props.session.api.changeEmailAddress({newEmail: newEmail}, (error?: string) => {
                if (error == null) {
                    setIsChangeEmailConfirm(true);
                    setNewEmail('');
                } else {
                    setChangeEmailError(error);
                }
            });
        }
    };

    const closeModal = () => {
        setIsModalOpen(false); 
        setNewEmail('');
        setIsChangeEmailConfirm(undefined);
        setChangeEmailError(undefined);
    };

    return props.session.isLoggedIn ? (
        <div className="container">
            <div className="mb-3">
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <h2>My Scripts</h2>
                    <button className="btn btn-primary btn-sm m-1" type="button" onClick={newScript}>New Script</button>
                </div>
                {(!props.localScript && Object.keys(scripts).length === 0) ?
                    'Have not created any scripts yet - click New Script above!' :
            
                    <ul>{
                        (props.localScript ? [['new', props.localScript.title]] : [])
                            .concat(Object.entries(scripts))
                            .map(([id, scriptTitle]) => (<li key={id}>
                                <Link to={"/script/" + id}>{(scriptTitle ? scriptTitle : '<Unnamed script>')}</Link>
                                {(id !== 'new' ? <span className="fas fa-trash-alt ml-2" onClick={() => {
                                    if (props.session.isLoggedIn) {
                                        if (window.confirm('Really want to delete script?')) {
                                            props.session.api.deleteScript(id, updateScriptList);
                                        }
                                    }
                                }}/> :
                                <span className="badge badge-warning ml-2">Unsaved</span>)}
                                </li>))
                    }</ul>
                }
            </div>
            <div>
                <h2>Account</h2>
                <div className="d-flex flex-row justify-content-between">
                <div>
                    {/* <div className="form-group row">
                        <label className="col-sm-2">Plan:</label><div className="col-sm-10">{props.session.user.plan.name} {props.session.user.plan.canUpgrade ? 
                        <button className="btn btn-primary btn-sm ml-3">Upgrade Plan!</button>: ''}</div>
                        </div>*/}
                    <div className="form-group row">
                        <label className="col-sm-2">User:</label><div className="col-sm-10">{props.session.user.name}</div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2">Email:</label><div className="col-sm-10">{props.session.user.email} {
                            props.session.user.isSocialLogin ? '' : <button 
                                className="btn btn-sm btn-primary ml-3"
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            >Change</button>}
                        </div>
                        <div className="col-sm-12"><input type="checkbox" checked={props.session.user.newsConsent === true} 
                            onChange={updateNewsConsent}></input> I wish to receive product news and information about screenwriting</div>
                    </div>
                </div>
                <div className="d-flex flex-column align-items-stretch">
                    <button className="btn btn-primary btn-sm m-1" onClick={signOut}>Log Out</button>
                    <button className="btn btn-dark btn-sm m-1" onClick={deleteAccount}>Delete Account</button>
                </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} toggle={closeModal}>
                <ModalBody>
                    {isChangeEmailConfirm ?
                    <div>
                        <legend>Confirm your e-mail</legend> 
                        An e-mail was sent to your new e-mail address. Follow the link in the e-mail to confirm the change.
                    </div>
                    :
                    <form>
                        <legend>Change e-mail address</legend>
                        {changeEmailError ? 
                        <div className="alert alert-danger">
                            {changeEmailError}
                        </div>
                        : ''}
                    <label htmlFor="email">New e-mail address</label>
                    <div className="form-group">
                        <input id="email" className="form-control" type="email"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                setNewEmail(event.target.value);
                                setChangeEmailError(undefined);
                                }}/>
                            {!isEmailValid() ? 
                             <small id="emailHelp" className="form-text text-muted">Must be a valid e-mail address</small> :
                            ''
                            }
                    </div>
                    </form>}
                </ModalBody>
                {isChangeEmailConfirm ? 
                    <ModalFooter>
                            <button className="btn btn-primary" color="primary" onClick={closeModal}>Done</button>
                    </ModalFooter> :
                    <ModalFooter>
                        <button className="btn btn-primary" color="primary" onClick={closeModal}>Cancel</button>
                        <button className="btn btn-primary" color="primary" 
                            disabled={!isEmailValid()}
                            onClick={changeEmailAddress}>OK</button>
                    </ModalFooter>
                }
            </Modal>
        </div>) : (<div>Not logged in</div>);
}

export default withRouter(Profile);