import React, { Component } from 'react';
import './Front.css';
import {
    withRouter, RouteComponentProps, Link
  } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { HashLink } from 'react-router-hash-link';

interface Props extends RouteComponentProps<any> {
    newDashHandler: (history: any) => void
}

interface State {
    screenshotUrl?: string;
}

class Front extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.createNewDash = this.createNewDash.bind(this);
        this.state = {
        }
    }

    componentDidMount() {
        const page = this.props.location.pathname + this.props.location.hash;
    }
  
    componentWillReceiveProps(nextProps: Props) {
        const currentPage = this.props.location.pathname + this.props.location.hash;
        const nextPage = nextProps.location.pathname + this.props.location.hash;
    }

    createNewDash() {
        this.props.newDashHandler(this.props.history)
    }

    render() {
        return (<div className="Front">
                    <div id="home" className="min-vh-100">
                        <div className="jumbotron Jumbo">
                                <div className="d-flex flex-column flex-shrink-1 align-self-stretch">
                                    <h1>Plan your story with PlotDash</h1>
                                    <p className="lead">
                                        Structure your plot and story beats with drag-and-drop 
                                        scene cards - available online wherever you write!
                                    </p>
                                    <p className="lead">
                                        <button onClick={this.createNewDash} className="btn btn-primary btn-lg">
                                            Start your new script!
                                        </button>
                                    </p>
                                    <div className="flex-grow-1"></div>
                                    <div className="h6 mt-5 align-self-center">Visualize your acts and highlight with colors</div>
                                    <div className="d-flex ScreenShots justify-content-between">
                                        <div className="d-flex flex-column align-items-center m-3 mb-0" style={{width: '220px'}}>
                                            <div onClick={() => {this.setState({screenshotUrl: 'board_screen.png'})}}>
                                                <img src="board_screen_thm.png" alt="Board Screenshot" width="220px" height="183"/>
                                            </div>
                                            <small>Rich editing and drag-and-drop board</small>
                                        </div>
                                        <div className="d-flex flex-column align-items-center m-3 mb-0" style={{width: '220px'}}>
                                            <div onClick={() => {this.setState({screenshotUrl: 'outline_screen.png'})}}>
                                                <img src="outline_screen_thm.png" alt="Outline Screenshot" width="220px" height="182"/>
                                            </div>
                                            <small>Chronological outline view</small>
                                        </div>
                                    </div>
                                </div>
                                <img className="WriterGraphic" src="writer_700.png" alt="Illustration of Writer"/>
                        </div>
                    </div>
                    <div className="min-vh-100">
                        <div id="support" className="jumbotron">
                            <h1>Support PlotDash</h1>
                            <p>PlotDash is free! Become a <a href="https://www.patreon.com/plotdash">Patreon supporter</a> to 
                            help support PlotDash's hosting costs and future development. Any contribution is appreciated!</p>
                        </div>
                    </div>
                    <div className="min-vh-100">
                        <div id="about" className="jumbotron">
                            <h1>About</h1>
                            <p>Corkboards and index cards have long been used by screenwriters, authors, comedians and performers to structure stories and performances. 
                            A good description of working with a script board can be found in the screenwriting classic <a target="_blank" href="https://www.amazon.com/gp/product/1932907009/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1932907009&linkCode=as2&tag=updab-20&linkId=d435f0fbaae6a8725e110958ea0fe2af">
                            Save The Cat! The Last Book on Screenwriting You'll Ever Need</a>.
                            </p>
                            <p>PlotDash is free for writers, you can create 100 scripts with 120 scenes each, but we appreciate your <HashLink smooth to="/#support">support</HashLink></p>
                            <p>PlotDash is a product of Updab AB and passion project of the owner William Saar, a Swedish
                                engineer who studied at the Stockholm Academy of Dramatic Arts. William has worked 
                                in the game industry and enjoys story structure and photography.</p>
                            <h2>Policies and legal</h2>
                            <p>Please enjoy our <a target="_blank" href="terms.html">Terms of Service</a> and 
                            <a target="_blank" href="privacy.html"> Privacy Policy</a>. PlotDash's terms of service
                            were adapted from <a target="_blank" href="https://en.wordpress.com/tos/"> Automattic's 
                            terms</a> published under a Creative Commons license.</p>
                            <p>© 2019 Updab AB</p>
                        </div>
                    </div>
                    <div className="min-vh-100">
                        <div id="contact" className="jumbotron">
                            <h1>Contact</h1>
                            <p>E-mail questions and feedback to info@plotdash.com. Follow or Tweet to <a href="https://twitter.com/plotdash">@plotdash</a> on Twitter</p>
                        </div>
                    </div>
                    <Modal isOpen={this.state.screenshotUrl != null} 
                        toggle={() => { this.setState({screenshotUrl: undefined});}} 
                        fade={false}
                        size='xl'>
                        <ModalBody>
                            <div>
                                <img src={this.state.screenshotUrl} style={{maxWidth: '100%'}}/>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>);
    }
}

export default withRouter(Front);