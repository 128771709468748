import React, { Component, ChangeEvent, RefObject, useEffect } from 'react';
import './Main.css';
import Front from './Front';
import App from './App';
import { Script, Scene } from './Models'
import {
    HashRouter as Router,
    Route,
    Switch,
    Redirect,
  } from 'react-router-dom'
import { Session, BackendApi, initializeSession, RemoteScriptCommandRunner, Globals, registerNewAccount, performSignIn, sendForgotPassword, verifyEmail, resetPassword } from './Net';
import { Alert, Popover, PopoverBody, Modal, ModalBody, ModalFooter } from 'reactstrap';
import TopBar from './TopBar';
import Profile from './Profile';
import { ScriptApi, ScriptApiCommandTranslator, LocalScriptCommandContext } from './Controllers';
import SignInRegister, { InitOptions, SignIn } from './SignInRegister';
import Redirecter from './Redirecter';
import BlockingModal from './BlockingModal';
import { useState } from 'react';



interface Notification {
    title: string;
    message: string;
    isWarning: boolean;
}

interface State {
    localScript?: Script;
    session: Session;
    errorMsg?: string;
    notification?: Notification;
    updateRunning: boolean;
    signIn?: InitOptions;
    verifyEmailSignInKey?: string;
    scriptApi?: ScriptApi;
    finishRegistrationState?: string;  
    cookiesDismissed: boolean;
    redirectTo?: string;
}

interface RouterTriggerProps {
    trigger: () => void
}
class RouterTrigger extends Component<RouterTriggerProps, any> {
    componentDidMount() {
        this.props.trigger();
    }

    render() {
        return (<div></div>);
    }
}

function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

class Main extends Component<any , State> {
    mainToolbarRef: React.RefObject<HTMLDivElement>;

    errorHandler = (errorMsg: string) => {
        this.setState({
            notification: {
                title: 'An error occurred',
                message: errorMsg,
                isWarning: false
            }
        });
    };
    constructor(props: any) {
        super(props);

        this.errorHandler = this.errorHandler.bind(this);
        this.clearNotification = this.clearNotification.bind(this);
        this.clearLocalScript = this.clearLocalScript.bind(this);
        this.getOrCreateScriptApi = this.getOrCreateScriptApi.bind(this);
        this.createScriptApi = this.createScriptApi.bind(this);
        this.triggerSignIn = this.triggerSignIn.bind(this);
        this.cancelSignIn = this.cancelSignIn.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.createNewDash = this.createNewDash.bind(this);
        this.reinitializeSession = this.reinitializeSession.bind(this);
        this.verifyEmailSignIn = this.verifyEmailSignIn.bind(this);
        this.resetPasswordSignIn = this.resetPasswordSignIn.bind(this);

        const self = this;
        this.state = {
            updateRunning: false,
            session: {
                isLoggedIn: false
            },
            cookiesDismissed: false
        }

        this.reinitializeSession('/profile');
        this.mainToolbarRef = React.createRef<HTMLDivElement>();
    }

    reinitializeSession(successPath?: string) {
        const self = this;
        initializeSession((session) => {
            if (session.isLoggedIn) {
                const s: Pick<State, 'session' | 'redirectTo'> = {
                    session,
                    redirectTo: undefined
                };
                const doRedirect = document.location.href.endsWith('/#/');
                if (doRedirect) {
                    s.redirectTo = successPath;
                };
                self.setState(s,
                    () => {
                        if (doRedirect) {
                            self.setState({redirectTo: undefined});
                        }
                    }
                    );
            }
        }, 
        this.errorHandler,
        true);
    }

    getOrCreateScriptApi(scriptId: string): ScriptApi {
        if (this.state.scriptApi == null || (this.state.scriptApi.getScriptId() !== scriptId)) {
            const scriptApi = this.createScriptApi(scriptId, this.errorHandler);
            this.setState({scriptApi: scriptApi});
            return scriptApi;
        }
        return this.state.scriptApi;
    }

    createScriptApi(scriptId: string, errorHandler: (err: string) => void): ScriptApi {
        const self = this;
        if (scriptId === 'new') {
            let localScript = this.state.localScript;
            if (!localScript) {
                localScript = {
                    title: '',
                    description: '',
                    board: {
                        nextSceneIndex: 1,
                        scenes: {
                        }
                    },
                    updateCount: 0
                };
                this.setState({localScript: localScript});
            }
            const scriptApi = new ScriptApiCommandTranslator(new LocalScriptCommandContext(localScript, 
                (msg) => {
                    self.setState({notification: {
                        title: 'Script update',
                        message: msg,
                        isWarning: true
                    }});
            }));
            scriptApi.newScene(1);
            return scriptApi;
        } else {
            return new ScriptApiCommandTranslator(
                new RemoteScriptCommandRunner(scriptId, errorHandler, 
                    (updateRunning: boolean) => {
                        self.setState({updateRunning: updateRunning});
                    },
                    {}));
        }
    }

    triggerSignIn(successHandler: (api: BackendApi) => void) {
        const self = this;
        this.setState({
            signIn: { 
                signIn: SignIn.Default, 
                socialSignIn: () => {
                    const signInDialog = window
                        .open(Globals.API_URL + '/api/auth/' + (Globals.isDevelopment ? 'local/' : 'fb/'));
                    if (signInDialog) {
                        const timer = setInterval(() => {
                            if (signInDialog.closed) {
                                clearInterval(timer);
                                initializeSession((session: Session) => {
                                    if (session.isLoggedIn) {
                                        successHandler(session.api);
                                        self.setState({ 
                                            session: session,
                                            signIn: undefined
                                        });
                                    } else {
                                        self.setState({ 
                                            session: session
                                        });
                                    }
                                },
                                self.errorHandler)
                            }
                        }, 500);
                    }
                },
                emailSignIn: (email: string, password: string, callback: (error?: string) => void) => {
                    performSignIn(email, password, (session: Session) => {
                        if (session.isLoggedIn) {
                            callback();
                            successHandler(session.api);
                                self.setState({ 
                                    session: session,
                                    signIn: undefined
                                });
                        } else {
                            callback('Sign in did not return a logged-in session');
                            self.setState({ 
                                session: session
                            });
                        }
                    }, (err) => {
                        callback(err);
                    });
                }
            }
        });
    }

    verifyEmailSignIn(key: string, password: string, callback: (error?: string) => void) {
        const self = this;
        verifyEmail(key, password, (session: Session) => {
            if (session.isLoggedIn) {
                callback();
                self.setState({ 
                    session: session,
                    signIn: undefined
                }, () => {
                    document.location.href = '/';
                });
            } else {
                callback('Sign in did not return a logged-in session');
                self.setState({ 
                    session: session
                });
            }
        }, (err: string) => {
            callback(err);
        });
    }

    resetPasswordSignIn(key: string, password: string, callback: (error?: string) => void) {
        const self = this;
        resetPassword(key, password, (session: Session) => {
            if (session.isLoggedIn) {
                callback();
                self.setState({ 
                    session: session,
                    signIn: undefined
                }, () => {
                    document.location.href = '/';
                });
            } else {
                callback('Sign in did not return a logged-in session');
                self.setState({ 
                    session: session
                });
            }
        }, (err: string) => {
            callback(err);
        });
    }

    cancelSignIn() {
        this.setState({signIn: undefined});
    }

    clearNotification() {
        this.setState({
            notification: undefined
        });
    }

    clearLocalScript() {
        this.setState({localScript: undefined});
    }

    createNewDash(history: any) {
        if (this.state.session.isLoggedIn) {
            this.state.session.api.newScript((scriptId: string) => {
                if (scriptId != null) {
                    history.push('/script/' + scriptId);
                }
            });
        } else {
            history.push('/script/new');
        }
    }

    tryRegister(data: {
        name: string;
        email: string;
        password: string;
        newsConsent: boolean;
        termsApproved: boolean;
    }, callback: (success: boolean) => void) {
        registerNewAccount(data, callback);
    }

    forgotPassword(email: string, callback: (success: boolean) => void) {
        sendForgotPassword(email, () => { callback(true); }, () => callback(false));
    }

    isPasswordValid(password?: string | null): boolean {
        if (password) {
            const l = password.length;
            return l > 7;
        }
        return false;
    }

    render() {
        const isIos = iOS();    // Determines if to render edit controls in top bar or bottom
        return (<Router> 
            <div className="Main">
                <div id="ErrorAnchor" className="position-fixed w-100" style={{height: 0, top: '50px'}}></div>
                <div className="MainArea">
                    <TopBar session={this.state.session} signInTrigger={this.triggerSignIn} updateRunning={this.state.updateRunning}
                        mainToolbarRef={isIos ? this.mainToolbarRef : undefined}/>
                    {this.state.redirectTo ? <Redirecter redirectTo={this.state.redirectTo}/> : ''}
                    <Switch>
                        <Route path="/script/:scriptId" render={props => 
                            (<App 
                                scriptId={props.match.params.scriptId}
                                isLocal={props.match.params.scriptId === 'new'}
                                session={this.state.session} 
                                scriptApiProvider={(scriptId: string) => {
                                    return this.getOrCreateScriptApi(scriptId);
                                }}
                                clearLocalScript={this.clearLocalScript}
                                signInTrigger={this.triggerSignIn}
                                mainToolbarRef={this.mainToolbarRef}
                            />
                            )}/>
                        <Route path="/profile" render={() => <Profile reloadListener={this.reinitializeSession} localScript={this.state.localScript} session={this.state.session} />} />
                        <Route path="/finishRegistration" render={() => <RouterTrigger trigger={() => { 
                            this.setState({
                                finishRegistrationState: document.location.href.substring(document.location.href.indexOf('state=') + 'state='.length)
                                }); 
                            }}/>}/>
                        <Route path="/verifyEmail" render={() => <RouterTrigger trigger={() => { 
                            const self = this;
                            const state = document.location.href.substring(document.location.href.indexOf('state=') + 'state='.length);
                            this.setState({
                                signIn: {
                                    signIn: SignIn.VerifyEmail,
                                    trySignIn: (password: string, callback: (error?: string) => void) => {
                                        self.verifyEmailSignIn(state, password, callback);
                                    }
                                }
                            });
                        }}/>}/> 
                        <Route path="/resetPassword" render={() => <RouterTrigger trigger={() => { 
                            const self = this;
                            const state = document.location.href.substring(document.location.href.indexOf('state=') + 'state='.length);
                            this.setState({
                                signIn: {
                                    signIn: SignIn.ResetPassword,
                                    trySignIn: (password: string, callback: (error?: string) => void) => {
                                        self.resetPasswordSignIn(state, password, callback);
                                    }
                                }
                            });
                            }}/>}/>
                        <Route path="/" render={() => (<Front newDashHandler={this.createNewDash}/>)}/>
                    </Switch>
                </div>
                <div className="d-flex flex-column bg-primary fixed-bottom w-100 py-2">
                    {!isIos ? <div style={{width: '100%', display: 'none'}} ref={this.mainToolbarRef} /> : <></>}
                </div>
                <Popover hideArrow={true} placement="top" isOpen={this.state.notification != null} target="ErrorAnchor" boundariesElement="window">
                    <Alert color={this.state.notification && this.state.notification.isWarning ? 'warning' : 'danger'} 
                        toggle={this.clearNotification} >{this.state.notification ? this.state.notification.title : ''} </Alert>
                    <PopoverBody className="alert">{this.state.notification ? this.state.notification!.message : ''}</PopoverBody>
                </Popover>
                {this.state.finishRegistrationState != null ? 
                    <BlockingModal key={this.state.finishRegistrationState}/> :
                    ''} 
                <SignInRegister 
                    initOptions={this.state.signIn}
                    tryRegister={this.tryRegister}
                    forgotPassword={this.forgotPassword}
                    cancel={this.cancelSignIn}
                    isPasswordValid={this.isPasswordValid}/>
            </div>
        </Router>);
    }
}

export default Main;