import React, { useEffect, useRef, useState } from 'react';
import { ColorResult, TwitterPicker } from 'react-color';
import './Card.css';
import { ScriptApi } from './Controllers';
import { UncontrolledPopover, Alert } from 'reactstrap';
import { Scene } from './Models';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactDOM from 'react-dom';
import { RefObject } from 'react';
import { MutableRefObject } from 'react';
import * as material from 'material-colors-ts';

const pickerColors = [
    'black', 'white',
    material.red['500'], material.pink['500'], material.purple['500'],
    material.deepPurple['500'], material.indigo['500'], material.blue['500'],
    material.lightBlue['500'], material.cyan['500'], material.teal['500'],
    material.green['500'], material.lightGreen['500'], material.lime['500'],
    material.yellow['500'], material.amber['500'], material.orange['500'],
    material.deepOrange['500'], material.brown['500'], material.blueGrey['500'],
]

interface Props {
    scene: Scene;
    api: ScriptApi;
    cardId: string;
    closeListener: () => void;
    title?: string;
    isEditing?: boolean;
    cardWidth: number;
    cardHeight?: number;
    sceneCount: number;
    mainToolbarRef?: React.RefObject<HTMLDivElement>;
    showOverlay?: boolean;
}

function DuplicateButton(props: {
    createDuplicate: () => void,
    buttonClasses: string;
}) {
    const [duplicateCreated, setDuplicateCreated] = useState(false);
    return <div className="d-flex flex-row mx-2" style={{position: 'relative'}}>
        <Alert color="info" style={{position: 'absolute', top: -70, left: -75, width: '300px'}} isOpen={duplicateCreated}>
            <h4>Duplicate Created!</h4>
        </Alert>
        <button type="button" className={props.buttonClasses} onClick={() => {
            props.createDuplicate();
            setDuplicateCreated(true);
            setTimeout(() => {
                setDuplicateCreated(false);
            }, 600);
        }}>Duplicate</button>
        </div>;
}

function ColorSelectorPopup(props: {
    mode: 'border' | undefined,
    target: RefObject<HTMLElement>;
    setBorderColor: (color?: string) => void,
    close: () => void
}) {
    return <UncontrolledPopover trigger="legacy" placement="bottom" modifiers={{ flip: { behavior: ['bottom'] } }} 
    target={props.target}
        isOpen={!!props.mode} toggle={() => {props.close()}}>
             <TwitterPicker colors={pickerColors} 
             triangle='hide'
            onChangeComplete={ (color: ColorResult) => {
                if (color.hex == '#ffffff') {
                    props.setBorderColor(undefined);
                } else {
                    props.setBorderColor(color.hex);
                }
                props.close();
            }}
        />
    </UncontrolledPopover>;
}

function EditCardToolbar(props: {
    duplicateSceneHandler: () => void;
    archiveSceneHandler: () => void;
    deleteHandler: () => void;
    closeHandler: () => void;
    saveHandler: () => void;
}) {
    const btnSize = window.matchMedia("(min-width: 992px)").matches ? "" : " btn-sm";

    return <div className="w100 d-flex flex-row justify-content-center">
            <div className="d-flex flex-row align-items-stretch">
                <DuplicateButton createDuplicate={props.duplicateSceneHandler} buttonClasses={"btn btn-secondary" + btnSize} />
                <button type="button" className={"btn btn-secondary mx-1 mx-lg-2" + btnSize}  onClick={props.archiveSceneHandler}>Archive</button>
            </div>
            <div className="d-flex flex-row align-items-stretch">
                <i className="fas fa-trash-alt fa-lg mx-2 mx-lg-3 align-self-center" onClick={props.deleteHandler}/>
                <button type="button" className={"btn btn-primary mx-1 mx-lg-2" + btnSize}  onClick={props.closeHandler}>Cancel</button>
                <button type="button" className={"btn btn-primary mx-1 mx-lg-2" + btnSize} onClick={props.saveHandler}>Save</button>
            </div>
            
        </div>;
}

function Card(props: Props) {
    const mainCardEditorRef = useRef<ReactQuill>(null);
    const detailsEditorRef = useRef<ReactQuill>(null);
    const borderColorRef = useRef(props.scene.borderColor ? props.scene.borderColor : undefined);

    const deleteListener = () => {
        if (window.confirm('Really delete scene?')) {
            props.api.deleteScene(props.cardId);
            props.closeListener();
        }
    }

    const saveListener = () => {
        props.api.editScene(props.cardId, {
            contents: mainCardEditorRef.current!.getEditor().getContents(), 
            details: detailsEditorRef.current!.getEditor().getContents(),
            borderColor: borderColorRef.current != null ? borderColorRef.current 
            : ''}); // clarify that we want to clear border color
        props.closeListener();
    }


    useEffect(() => {
        if (props.isEditing) {
            ReactDOM.render(<EditCardToolbar 
            duplicateSceneHandler={() => { 
                props.api.newScene(props.sceneCount + 1, {
                    contents: mainCardEditorRef.current!.getEditor().getContents(),
                    borderColor: borderColorRef.current
                });
            }}
            archiveSceneHandler={() => {
                props.api.moveToArchive(props.cardId, { 
                    ...props.scene, 
                    borderColor: borderColorRef.current, 
                    contents: mainCardEditorRef.current!.getEditor().getContents()
                });
                props.closeListener();
            }}
            saveHandler={saveListener}
            closeHandler={props.closeListener}
            deleteHandler={deleteListener}
            />, 
                props.mainToolbarRef!.current!);
            props.mainToolbarRef!.current!.style.display = 'block';
            return () => {
                props.mainToolbarRef!.current!.style.display = 'none'
            }
        }
    }, [props.isEditing]);
    return <div className="d-flex flex-column flex-lg-row flex-grow-1 position-relative" style={{zIndex: props.isEditing ? 1 : 0}}>
            {props.isEditing ? <>
                {props.showOverlay ? 
                    <div style={{background: '#000000', opacity: 0.7, position: 'fixed', left: 0, top: 0, bottom: 0, right: 0, zIndex: 0.5}} />
                    :
                    <></>}
                <MainCard borderColorRef={borderColorRef} 
                    cardId={props.cardId} 
                    isEditing={props.isEditing} 
                    contents={props.scene.contents} 
                    cardWidth={props.cardWidth} 
                    cardHeight={props.cardHeight} 
                    mainCardEditorRef={mainCardEditorRef} 
                    detailsEditorRef={detailsEditorRef}/>
                <div className="d-flex flex-column flex-items-stretch mt-5 mt-lg-0 ml-lg-2 align-self-stretch">
                    <Details isEditing={props.isEditing} contents={props.scene.details} quillRef={detailsEditorRef}/>
                </div>
            </>:
            <MainCard borderColorRef={borderColorRef} 
                cardId={props.cardId} 
                isEditing={props.isEditing} 
                contents={props.scene.contents} 
                cardWidth={props.cardWidth} 
                cardHeight={props.cardHeight} 
                mainCardEditorRef={mainCardEditorRef} detailsEditorRef={detailsEditorRef} />
        }
        </div>;
}

function MainCard(props: {
    isEditing?: boolean;
    cardId: string;
    cardWidth: number;
    cardHeight?: number;
    contents: any;
    mainCardEditorRef: RefObject<ReactQuill>;
    detailsEditorRef: RefObject<ReactQuill>;
    borderColorRef: MutableRefObject<string | undefined>;
}) {
    const [borderColor, setBorderColor] = useState<string | undefined>(props.borderColorRef.current);
    const [colorSelectorOpen, setColorSelectorOpen] = useState<'border'>();
    const borderColorSelectorTarget = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (props.isEditing) {   
            const cardBorderButton = (document.getElementsByClassName("ql-card-border")[0] as HTMLButtonElement);
            borderColorSelectorTarget.current = cardBorderButton;
            cardBorderButton.onclick = () => setColorSelectorOpen('border');   
            props.mainCardEditorRef.current!.getEditor().enable();
            props.mainCardEditorRef.current!.getEditor().focus();
            return () => {
                cardBorderButton.onclick = null;
                props.mainCardEditorRef.current!.getEditor().blur();
                props.mainCardEditorRef.current!.getEditor().disable();
            };
        } 
    }, [props.isEditing, props.mainCardEditorRef]);

    useEffect(() => {
        props.borderColorRef.current = borderColor;
    }, [borderColor, props.borderColorRef])

    return (<><div 
        id={'MainCardBorder'+props.cardId}
        className="card CardTop d-flex flex-direction-column align-items-stretch" 
        style={{
        border: 'solid', 
        borderWidth: borderColor ? '4px 4px 30px 4px' : '1px', 
        borderColor: borderColor ? borderColor : '#dddddd',
        width: props.cardWidth + "px", 
        minWidth: props.cardWidth + "px",
        maxWidth: props.cardWidth + "px"}}
        >
        <div className="EditorWrapper" style={props.isEditing ? {} : {pointerEvents: 'none'}}>
                <ReactQuill id="MainCard" 
                ref={props.mainCardEditorRef} 
                className="flex-grow-1 d-flex flex-column align-items-stretch"
                style={{minHeight: props.cardHeight}} 
                theme="snow"
                placeholder="Describe your scene and drag to place it in the timeline"
                modules={{
                    toolbar: props.isEditing ? {
                        container: [
                            [ 'bold', 'italic', 'underline', 'strike' ],
                            [{ 'color': pickerColors }, { 'background': pickerColors }],
                            ['card-border']
                        ]
                    } : false
                }}
                defaultValue={props.contents}
                bounds={'#MainCardBorder'+props.cardId}
                />
        </div>
        </div>
        {props.isEditing ? 
            <ColorSelectorPopup mode={colorSelectorOpen} 
                    target={borderColorSelectorTarget}
                    close={() => setColorSelectorOpen(undefined)} 
                    setBorderColor={setBorderColor} /> :
            <></>}
        </>
        );
}

function Details(props: { isEditing: boolean, contents?: any, quillRef: React.RefObject<ReactQuill> }) {
    return <div id="details-card" className="card bg-white flex-column flex-grow-1" style={{minWidth: '500px', minHeight:'200px'}}>
        <div className="card-header text-dark bg-light text-left h4">Details</div>
        <div id="DetailsView" 
            className="d-flex flex-column card-text align-self-stretch align-items-stretch flex-grow-1 text-left p-1">
            <ReactQuill className="d-flex flex-column align-items-stretch flex-grow-1" theme="snow" placeholder="More information about the scene"
            modules={{toolbar: [[{ 'size': [] }],
            [ 'bold', 'italic', 'underline', 'strike' ],
            [{ 'color': pickerColors }, { 'background': pickerColors }],
            ]}}
            value={props.contents}
            ref={props.quillRef}/>
        </div>
    </div>;
}

export default Card;