import React, { Component, ChangeEvent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

enum DialogMode {
    SignIn,
    Register,
    RegistrationLinkSent,
    ForgotPassword,
    ResetPasswordLinkSent
}

export enum SignIn {
    VerifyEmail,
    ResetPassword,
    Default
}

export type InitOptions = {
    signIn: SignIn.VerifyEmail | SignIn.ResetPassword,
    trySignIn: (password: string, callback: (error?: string) => void) => void
} | {
    signIn: SignIn.Default,
    emailSignIn: (email: string, password: string, callback: (error?: string) => void) => void,
    socialSignIn: () => void
};

interface Props {
    tryRegister: (data: {
            name: string;
            email: string;
            password: string;
            newsConsent: boolean;
            termsApproved: boolean;
        }, callback: (success: boolean) => void) => void;
    forgotPassword: (email: string, callback: (success: boolean) => void) => void;
    isPasswordValid: (pw: string) => boolean;
    cancel: () => void;
    initOptions?: InitOptions;
}

interface State {
    mode: DialogMode,
    name: string,
    email: string,
    password: string,
    verifyPassword: string,
    newsConsent: boolean;
    termsApproved: boolean;
    submitFailure?: string;
}

class SignInRegister extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.register = this.register.bind(this);
        this.emailSignIn = this.emailSignIn.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.verifyEmail = this.verifyEmail.bind(this);
        this.resetPassword = this.resetPassword.bind(this);

        this.state = this.getClearedState();
    }

    componentDidMount() {
    }

    getClearedState() {
        return {
            mode: DialogMode.SignIn,
            name: '',
            email: '',
            password: '',
            verifyPassword: '',
            newsConsent: false,
            termsApproved: false,
            submitFailure: undefined
        };
    }

    componentWillReceiveProps(props: Props) {
        this.setState(this.getClearedState());
    }

    emailSignIn() {
        const self = this;
        if (this.props.initOptions && this.props.initOptions.signIn === SignIn.Default) {
            this.props.initOptions.emailSignIn(this.state.email, this.state.password, (error?: string) => {
                if (error != null) {
                    self.setState({submitFailure: 'Sign in failed, check the e-mail address and password'});
                }
            });
        } else {
            console.log('Error: emailSignIn called with init options ' + this.props.initOptions);
        }
    }


    verifyEmail() {
        const self = this;
        if (this.props.initOptions && this.props.initOptions.signIn === SignIn.VerifyEmail) {
            this.props.initOptions.trySignIn(this.state.password, (error?: string) => {
                if (error != null) {
                    self.setState({submitFailure: 'Failed to verify the e-mail, check the password.'});
                }
            });
        } else {
            console.log('Error: verifyEmail called with init options ' + this.props.initOptions);
        }
    }

    resetPassword() {
        const self = this;
        if (this.props.initOptions && this.props.initOptions.signIn === SignIn.ResetPassword) {
            this.props.initOptions.trySignIn(this.state.password, (error?: string) => {
                if (error != null) {
                    self.setState({submitFailure: 'Failed to reset password: ' + error});
                }
            });
        } else {
            console.log('Error: resetPassword called with init options ' + this.props.initOptions);
        }
    }

    register() {
        const self = this;
        this.props.tryRegister({
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            newsConsent: this.state.newsConsent,
            termsApproved: this.state.termsApproved
        }, (success: boolean) => {
            if (success) {
                self.setState({mode: DialogMode.RegistrationLinkSent});
            }
        });
    }

    forgotPassword() {
        const self = this;
        this.props.forgotPassword(this.state.email, (success: boolean) => {
            if (success) {
                self.setState({mode: DialogMode.ResetPasswordLinkSent});
            } else {
                self.setState({submitFailure: 'Sending failed! Maybe a different address was used to register?'});
            }
        })
    }

    handleNameChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({name: event.target.value});
    }

    handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({email: event.target.value,
            submitFailure: undefined});
    }

    handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({password: event.target.value,
            submitFailure: undefined});
    }

    isEmailValid(): boolean {
        return !this.props.initOptions || 
            this.props.initOptions.signIn !== SignIn.Default ||
            (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email));
    }

    renderResetPasswordLinkSent() {
        return (<>
            <ModalBody>
                <legend>Reset password link sent</legend>
                <p>
                    A link has been sent to reset your password. Check your spam folder if you haven't received the e-mail shortly.
                </p>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary" color="primary" onClick={this.props.cancel}>Close</button>
            </ModalFooter>
        </>);
    }

    renderRegistrationLinkSent() {
        return ( <ModalBody>
            <legend>Confirm your e-mail address</legend>
            <p>
                Registration almost complete! Please click on the link in the e-mail you should receive shortly to confirm your e-mail and activate your account. Check your spam folder if you haven't received the e-mail shortly.
            </p>
            <button className="btn btn-primary" onClick={() => {
                this.setState(this.getClearedState());
                }}>Done</button>
        </ModalBody>);
    }

    renderRegisterAccount() {
        return (<>
            <ModalBody>
                <form>
                    <legend className="m-0 p-0">Register new account</legend>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input id="name" className="form-control" type="text" value={this.state.name} onChange={this.handleNameChange}/>
                        {!this.state.name ? 
                            <small id="nameHelp" className="form-text text-muted">Please provide your full name.</small> :
                        ''}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">E-mail address</label>
                        <input id="email" className="form-control" type="email" value={this.state.email} onChange={this.handleEmailChange}/>
                        {!this.isEmailValid() ? 
                        <small id="emailHelp" className="form-text text-muted">Must be a valid e-mail address</small> :
                        ''}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input id="password" className="form-control" type="password" value={this.state.password} onChange={this.handlePasswordChange}/>
                        {!this.props.isPasswordValid(this.state.password) ?
                            <small id="passwordHelp" className="form-text text-muted">Must be 8 characters or longer</small> :
                            ''}
                    </div>
                    <div><input name="termsApproved" type="checkbox"
                        onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                                this.setState({termsApproved: ev.currentTarget.checked});
                            }}/> I have read and agree to PlotDash's <a target="_blank" href="terms.html" onClick={() => {
                            }}>terms of service</a></div>
                    <div><input name="newsConsent" type="checkbox"
                        onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                            this.setState({newsConsent: ev.currentTarget.checked})
                            }}/> I want to receive e-mail with news about PlotDash and information about screenwriting 
                            (you can always change this on your profile page)</div>
                </form>
            </ModalBody>
            <ModalFooter>
                    <button className="btn btn-primary" color="primary" 
                        disabled={!this.state.termsApproved || !this.isEmailValid() || !this.props.isPasswordValid(this.state.password) || !this.state.name} 
                        onClick={this.register}>Register</button>
                    <button className="btn btn-primary" color="primary" onClick={this.props.cancel}>Close</button>
            </ModalFooter>
        </>);
    }

    renderVerifyEmail() {
        return (<ModalBody>
            <form>
                <legend className="m-0 p-0">Finish confirming e-mail</legend>
                {this.state.submitFailure ? 
                        <div className="alert alert-danger">{this.state.submitFailure}</div> :
                        ''}
                <small>Confirm your e-mail by entering your password.</small>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input id="password" className="form-control" type="password" value={this.state.password} onChange={this.handlePasswordChange}/>
                </div>
            </form>
            <div className="d-flex flex-direction-row justify-content-end">
            <button className="btn btn-primary" color="primary" 
                onClick={this.verifyEmail}>Send</button>
            </div>
        </ModalBody>)
    }

    renderSignIn() {
        return (!this.props.initOptions ? '' : 
            <ModalBody>
            <div>
                <form>
                    <legend className="m-0 p-0">Sign In with E-mail and Password</legend>
                    {this.state.submitFailure ? 
                        <div className="alert alert-danger">{this.state.submitFailure}</div> :
                        ''}
                    <small>Don't have an account? <u className="text-white text-decoration-underline" 
                        onClick={() => { this.setState({mode: DialogMode.Register}); }}>Create an account FREE here</u></small>
                    <div className="form-group">
                        <label htmlFor="email">E-mail address</label>
                        <input id="email" className="form-control" type="email" value={this.state.email} onChange={this.handleEmailChange}/>
                        {!this.isEmailValid() ? 
                            <small id="emailHelp" className="form-text text-muted">Must be a valid e-mail address</small> :
                            <></>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input id="password" className="form-control" type="password" value={this.state.password} onChange={this.handlePasswordChange}/>
                        <small id="emailHelp" className="form-text text-muted">
                            <u className="text-white text-decoration-underline" onClick={() => { this.setState({mode: DialogMode.ForgotPassword}); }}>Forgot your password?</u></small>
                    </div>
                 </form>
                 <div className="d-flex flex-direction-row justify-content-end">
                    <button className="btn btn-primary" color="primary" 
                        disabled={!this.isEmailValid()} 
                        onClick={this.emailSignIn}>Sign In</button>
                 </div>
            {
            // this.props.initOptions.signIn === SignIn.Default ? 
            //     <div>
            //         <legend>Or Sign In / Register with social account</legend>
            //         <p className="small">
            //         Signing up is FREE and we don't sell your information to marketers.
            //         </p>
            //         <img src="signin_facebook.png" width="247" height="40" onClick={this.props.initOptions.socialSignIn} 
            //         alt='Sign in with Facebook'/>
            //         <p className="small">
            //         By signing in with Facebook you allow PlotDash to store information from your public profile 
            //         and e-mail address, and accept that we store a persistent authentication cookie in your browser. 
            //         Read more in our 
            //         <a target="_blank" href="privacy.html" onClick={() => {
            //             ReactGA.event({
            //                 category: 'User',
            //                 action: 'Viewed privacy'
            //               });
            //         }}> Privacy Policy</a></p>
            //     </div> : 
            // ''
            }
            </div>
        </ModalBody>);
    }

    renderForgotPassword() {
        return (<>
            <ModalBody>
                <div>
                    <form>
                        <legend className="m-0 p-0">Reset your password</legend>
                        {this.state.submitFailure ? 
                            <div className="alert alert-danger">{this.state.submitFailure}</div> :
                            ''}
                        <p>Specify the e-mail you used to register and a link to reset your password will be sent to that address</p>
                        <div className="form-group">
                            <label htmlFor="email">E-mail address</label>
                            <input id="email" className="form-control" type="email" value={this.state.email} onChange={this.handleEmailChange}/>
                            {!this.isEmailValid() ? 
                                <small id="emailHelp" className="form-text text-muted">Must be a valid e-mail address</small> :
                                ''}
                        </div>
                    </form>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary" color="primary" 
                            disabled={!this.isEmailValid()} 
                            onClick={this.forgotPassword}>Send Link</button>
                <button className="btn btn-primary" color="primary" onClick={this.props.cancel}>Close</button>
            </ModalFooter>
        </>);
    }

    renderResetPassword() {
        return <>
            <ModalBody>
                    <legend>
                        Reset password
                    </legend>
                    {this.state.submitFailure ? 
                        <div className="alert alert-danger">{this.state.submitFailure}</div> :
                        ''}
                    <p>
                        Specify your new password
                    </p>
                    <input name="state" type="hidden" value={document.location.href.substring(document.location.href.indexOf('state=') + 'state='.length)}></input>
                    <div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input id="password" name="password" className="form-control" type="password" 
                            value={this.state.password || ''} onChange={(ev: ChangeEvent<HTMLInputElement>) => {this.setState({password: ev.target.value});}}/>
                        {!this.props.isPasswordValid(this.state.password) ?
                            <small id="passwordHelp" className="form-text text-muted">Must be 8 characters or longer</small> :
                            ''}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Verify Password</label>
                        <input id="password" className="form-control" type="password" 
                            value={this.state.verifyPassword  || ''} onChange={(ev: ChangeEvent<HTMLInputElement>) => {this.setState({verifyPassword: ev.target.value});}}/>
                        {(this.state.password !== this.state.verifyPassword) ?
                            <small id="passwordHelp" className="form-text text-muted">Must match the password</small> :
                            ''}
                    </div>
                    </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary"
                    disabled={!this.props.isPasswordValid(this.state.password) ||
                        (this.state.password !== this.state.verifyPassword)}
                    onClick={this.resetPassword}
                    >Reset Password</button>
            </ModalFooter>
        </>;
    }

    render() {
        return (<Modal isOpen={!!this.props.initOptions} toggle={this.props.cancel} fade={false}>
            {!this.props.initOptions ? '' : 
                this.state.mode === DialogMode.RegistrationLinkSent ?
                this.renderRegistrationLinkSent() :
                this.state.mode === DialogMode.ResetPasswordLinkSent ?
                this.renderResetPasswordLinkSent() :
                this.state.mode === DialogMode.Register ? 
                this.renderRegisterAccount() :
                this.state.mode === DialogMode.ForgotPassword ?
                this.renderForgotPassword() :
                    this.props.initOptions.signIn === SignIn.VerifyEmail ? 
                    this.renderVerifyEmail() :
                    this.props.initOptions.signIn === SignIn.ResetPassword ?
                    this.renderResetPassword() :
                    this.renderSignIn()}
        </Modal>);
    }
}

export default SignInRegister;