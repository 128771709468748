import React, { ChangeEvent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

interface Props {
    key: string;
}

interface State {
    termsApproved?: boolean     // Used for finish,
    password?: string;          // Used for resetPassword
    verifyPassword?: string;    // Used for resetPassword
}

class BlockingModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }


    renderFinishRegistration() {
        return ;
    }

    render() {
        return <Modal isOpen={true} backdrop='static' size='lg'>
            <form action="/api/auth/terms" method="GET">
            <ModalBody>
                <legend>Finish registration</legend>
                    <input name="state" type="hidden" value={this.props.key}></input>
                    <div><input name="termsApproved" type="checkbox" 
                        onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                            this.setState({termsApproved: ev.currentTarget.checked});
                        }}/> I have read and agree to PlotDash's <a target="_blank" 
                        href="terms.html">terms of service</a></div>
                    <div><input name="newsConsent" type="checkbox"/> I want to receive e-mail with news about PlotDash and information about screenwriting (you can always change this on your profile page)</div>
            </ModalBody>
            <ModalFooter>
                <input type="submit" className="btn btn-primary" value="Register" disabled={!this.state.termsApproved}></input>
            </ModalFooter>
            </form>
        </Modal>
    }
}

export default BlockingModal;