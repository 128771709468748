import React, { useState } from 'react';
import './TopBar.css';
import {
    HashLink as Link
  } from 'react-router-hash-link';
import { Session } from './Net';
import { Navbar, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { RefObject } from 'react';

interface Props {
    session: Session;
    signInTrigger: (successHandler: ()=>void) => void;
    updateRunning: boolean;
    mainToolbarRef?: RefObject<HTMLDivElement>  // Don't include toolbar if missing
}

function TopBar(props: Props & React.HTMLAttributes<HTMLDivElement>) {
    const [menuOpen, setMenuOpen] = useState(false);
    return (<div className="d-flex flex-column sticky-top bg-dark" id={props.id}>
                <div className="TopBar">
                <Link to="/" className="LogoWideLink"><img className="LogoWide" src="logo_400.png" alt="PlotDash"/></Link>
                <Link to="/" className="LogoSquareLink"><img className="LogoSquare" src="logo_sq100.png" alt="PlotDash"/></Link>
                <div className="MenuAndUser">
                    <div id="OngoingUpdate" style={{display: (props.updateRunning ? 'block' : 'none') }}><small className="text-muted" id="OngoingUpdateText">Saving</small><i className="fa fa-upload pt-2 pr-2 pb-2 pl-1"/></div>
                    {!props.session.isLoggedIn ? 
                        (<a onClick={() => {props.signInTrigger(() => {})}}><div className="text-small">Sign In / Register</div></a>) : 
                        (<Link to="/profile"><div><i className="fas fa-user"/><span id="UserNameText" className="ml-1">{props.session.user.name}</span></div></Link>)}
                    <Navbar id="NavMenu" color="faded" dark className="border-0 p-0">                            
                        <Dropdown isOpen={menuOpen} toggle={() => {
                            setMenuOpen(!menuOpen);
                        }} inNavbar 
                        direction={window.matchMedia("(max-height: 500px) and (orientation: landscape)").matches ? "down" : "left"}> 
                            <DropdownToggle tag="div"><span className="navbar-toggler-icon"></span></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>
                                    <Link smooth to="/#home"><div className="mw-100">Home</div></Link>
                                </DropdownItem>
                                <DropdownItem>
                                    <Link smooth to="/#support"><div className="mw-100">Support PlotDash</div></Link>
                                </DropdownItem>
                                <DropdownItem>
                                    <Link smooth to="/#about"><div className="mw-100">About</div></Link>
                                </DropdownItem>
                                <DropdownItem>
                                    <Link smooth to="/#contact"><div className="mw-100">Contact</div></Link>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Navbar>
                </div>
                </div>
                {props.mainToolbarRef ? <div className="bg-primary py-2" style={{width: '100%', display: 'none'}} ref={props.mainToolbarRef} /> : <></>}
            </div>);
}

export default TopBar;