import { Script, Scene, Board } from "./Models";
import { ScriptListener, ScriptCommandRunner } from "./Net";
import { Command, CommandAction, Proxy } from "flushout";
import { validateSync } from 'class-validator';


// api for a single script
export interface ScriptApi { 
    getScriptId(): string;
    subscribe: (scriptListener: (s: Script) => void) => void; 
    editScript: (title: string | null, description: string | null) => void; 
    newScene: (index: number, initialProps?: Pick<Scene, 'borderColor' | 'contents'>) => void; 
    moveScene: (sceneId: string, x: any, y: any) => void; 
    editScene: (sceneId: string, update: Partial<Scene>) => void; 
    deleteScene: (sceneId: string) => void;
    setNumberOfActs: (number: number) => void;
    restoreFromArchive: (id: string, scene: Scene) => void;
    moveToArchive: (id: string, scene: Scene) => void;
}

export class ScriptApiCommandTranslator implements ScriptApi {
    constructor(private readonly runner: ScriptCommandRunner) {}
    getScriptId(): string {
        return this.runner.getScriptId();
    }
    subscribe(scriptListener: ScriptListener) {
        this.runner.setScriptListener(scriptListener);
    }    
    editScript(title: string | null, description: string | null) {
        const props: any = {};
        if (title != null) {
            props.title = title;
        }
        if (description != null) {
            props.description = description;
        }
        const command: Command<Script> = {
            action: CommandAction.Update,
            props
        };
        this.runner.apply(command);
    }
    setNumberOfActs(acts: number) {
        const command: Command<Board> = {
            path: ['board'],
            action: CommandAction.Update,
            props: {
                acts
            }
        };
        this.runner.apply(command);
    }
    newScene(index: number, initialProps?: Pick<Scene, 'borderColor' | 'contents'>) {
        const idx = index % 80 || 1;
        const command: Command<Scene> = {
            path: ['board','scenes'],
            action: CommandAction.Create,
            props: {
                x: ((idx - 1) % 10) * 0.01 + Math.floor((idx - 1) / 10) * 0.1 + 0.02,
                y: ((idx - 1) % 10) * 0.05 + 0.05,
                ...(initialProps || {})
            }
        };
        this.runner.apply(command);
    };
    moveScene(sceneId: string, x: any, y: any) {
        const command: Command<Scene> = {
            path: ['board','scenes', sceneId],
            action: CommandAction.Update,
            props: {
                x: x,
                y: y
            }
        };
        this.runner.apply(command);
    }
    editScene(sceneId: string, update: Partial<Scene>) {
        const props: any = {};
        if (update.contents != null) {
            props.contents = update.contents;
        }
        if (update.details != null) {
            props.details = update.details;
        }
        if (update.borderColor != null) {
            // Must send null to clear border color, JSON encoding will exclude undefined value
            props.borderColor = update.borderColor === '' ? null : update.borderColor;
        }
        const command: Command<Scene> = {
            path: ['board','scenes', sceneId],
            action: CommandAction.Update,
            props
        };
        this.runner.apply(command);
    };
    deleteScene(sceneId: string) {
        const command: Command = {
            path: ['board','scenes', sceneId],
            action: CommandAction.Delete
        };
        this.runner.apply(command);
    };
    restoreFromArchive(sceneId: string, scene: Scene) {
        const createCommand: Command = {
            path: ['board','scenes'],
            action: CommandAction.Create,
            props: scene
        };
        const deleteCommand: Command = {
            path: ['board','archive', sceneId],
            action: CommandAction.Delete
        };
        this.runner.apply(createCommand);
        this.runner.apply(deleteCommand);
    }
    moveToArchive(sceneId: string, scene: Scene) {
        const createCommand: Command = {
            path: ['board','archive'],
            action: CommandAction.Create,
            props: scene,
            parentDefault: {}
        };
        const deleteCommand: Command = {
            path: ['board','scenes', sceneId],
            action: CommandAction.Delete
        };
        this.runner.apply(createCommand);
        this.runner.apply(deleteCommand);
    }
}

export class LocalScriptCommandContext implements ScriptCommandRunner {
    private readonly proxy: Proxy<Script>;
    listener?: ScriptListener;
    constructor(script: Script, private infoHandler: (msg: string) => void) {
        const snapshot = { document: script, commandCount: 0 };
        this.proxy = new Proxy(snapshot, {
            interceptor: (script: Script, command: Command) => {
                if (command.action === CommandAction.Create && 
                    command.path?.some((segment) => { return segment === 'scenes'; }) && 
                    (Object.keys(script.board.scenes).length > 2)) {
                    return {
                        rejection: 'Please save your script before creating more scenes.'
                    };
                }
                const errors = validateSync(command);
                if (errors.length > 0) {
                    return {
                        rejection: 'Failed validation: ' + errors[0]
                    };
                }
                return undefined;
            }
        });
    }
    getScriptId() {
        return 'new';
    }
    apply(command: Command) {
        const result = this.proxy.apply(command);
        if (!result.isSuccess) {
            this.infoHandler(result.error);
        } else if (this.listener) {
            this.listener(this.proxy.getDocument());
        }
    }
    setScriptListener(scriptListener: ScriptListener): void {
        this.listener = scriptListener;
        this.listener(this.proxy.getDocument());
    }
}
