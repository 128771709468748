import { Script, NewScriptCommand, ScriptContext, UserInfo } from "./Models";
import { validateSync } from "class-validator";
import { Command, Proxy, Interceptor, CompletionBatch, ApplyResult, Snapshot } from "flushout";

export const Globals = {
    isDevelopment: process.env.NODE_ENV === "development",
    API_URL: (process.env.NODE_ENV === "development") ? "http://" + window.location.hostname + ":3030" : "https://plotdash.com"
}
export interface ScriptCommandRunner {
    getScriptId(): string;
    apply(command: Command): void;
    setScriptListener(scriptListener: (s: Script) => void): void;
}

export type ScriptListener = (script: Script) => void;

export type LoggedInSession = {
    isLoggedIn: true;
    user: UserInfo;
    api: BackendApi;
};
export type Session = { isLoggedIn: false; } | LoggedInSession;

export class BackendApi {
    constructor(private errorHandler: (err: string) => void) {}
    
    getAllScripts(callback: (scriptIdsToTitles: Record<string, string>) => void) {
        sendFetch('GET', '/api/script', null, (data) => {
            callback(data as Record<string, string>)
        }, this.errorHandler);
    }
    newScript(callback: (scriptId: string) => void, initialData?: Script) {
        sendFetch('POST', '/api/script', new NewScriptCommand(initialData), (data) => {
            callback(data as string)
        }, this.errorHandler);
    }
    deleteScript(scriptId: string, callback: () => void) {
        sendFetch('DELETE', '/api/script/' + scriptId, null, (_) => {
            callback()
        }, this.errorHandler);
    }
    deleteAccount(password: string, callback: () => void) {
        sendFetch('DELETE', '/api/auth/account', {password}, (_) => {
            callback()
        }, this.errorHandler);
    }
    signOut(successCallback: () => void) {
        sendFetch('DELETE', '/api/auth', null, successCallback, this.errorHandler);
    }
    updateUser(data: { newsConsent: boolean; }, resultHandler: () => void) {
        sendFetch('PUT', '/api/user', data, resultHandler, this.errorHandler)
    }
    changeEmailAddress(data: { newEmail: string }, resultHandler: (error?: string) => void) {
        sendFetch('PUT', '/api/auth', data, () => { resultHandler(); }, resultHandler);
    }
}

export class RemoteScriptCommandRunner implements ScriptCommandRunner {
    listener?: ScriptListener;
    proxy?: Proxy<Script>;
    interceptor: Interceptor<Script>;
    isSaving: boolean = false;
    flush?: CompletionBatch;
    constructor(
        private readonly scriptId: string, 
        private readonly errorHandler: (err: string) => void, 
        private readonly updateStatusNotifier: (running: boolean) => void,
        private readonly scriptContext: ScriptContext) {
            this.interceptor = (script: Script, command: Command) => {
                    const errors = validateSync(command);
                    if (errors.length > 0) {
                        return {
                            rejection: 'Failed validation: ' + errors[0]
                        };
                    }
                    return undefined;
                };
    }
    getScriptId() {
        return this.scriptId;
    }
    apply(command: Command) {
        if (this.proxy) {
            const result = this.proxy.apply(command);
            if (!result.isSuccess) {
                this.errorHandler(result.error);
                return;
            }
            if (this.listener) {
                this.listener(this.proxy.getDocument());
            }
            this.triggerFlush();
        } else {
            this.errorHandler('No script initialized');
        }
    }   
    triggerFlush() {
        if (this.proxy != null && !this.proxy.isFlushInProgress()) {
            this.flush = this.proxy.beginFlush();
            this.sendFlush(this.proxy, this.flush);
        }
    }
    sendFlush(proxy: Proxy<Script>, flush: CompletionBatch) {
        const self = this;
        this.updateStarted();
        sendFetch('PUT', '/api/script/'+ this.scriptId, 
            flush, 
            (result: ApplyResult<Script>) => {
                if (result.errors != null) {
                    result.errors.map(e => console.log(e));
                    self.errorHandler('Received errors when sending script changes.');
                }
                proxy.endFlush(result.sync);
                if (self.listener && self.proxy) {
                    self.listener(self.proxy.getDocument())
                }
                self.flush = undefined;
                if (proxy.hasUnflushedCommands()) {
                    self.flush = proxy.beginFlush();
                    self.sendFlush(proxy, self.flush);
                } else {
                    self.updateFinished();
                }
            }, 
            (err) => {
                proxy.cancelFlush(flush);
                self.flush = undefined;
                this.errorHandler('Failed to apply script update. Error:\n' + err);
            });
    }
    setScriptListener(scriptListener: ScriptListener): void {
        this.listener = scriptListener;
        this.fetchLatestScript();
    }
    fetchLatestScript() {
        const self = this;
        sendFetch('GET', '/api/script/'+this.scriptId, null, (data) => {
            const snapshot = data as Snapshot<Script>;
            self.updateFinished();
            self.proxy = new Proxy(snapshot);
            if (self.listener) {
                self.listener(self.proxy.getDocument())
            }
        }, (err) => {
            self.updateFinished();
            this.errorHandler(err);
        });
    }
    updateStarted() {
        this.isSaving = true;
        this.updateStatusNotifier(true);
    }
    updateFinished() {
        this.isSaving = false;
        this.updateStatusNotifier(false);
    }
}

export async function registerNewAccount(data: {
        name: string;
        email: string;
        password: string;
        newsConsent: boolean;
        termsApproved: boolean;
    },
    callback: (success: boolean) => void) { 
    const p = new Promise<boolean>((resolve, reject) => {
        sendFetch('POST', '/api/auth/register', data, (data) => {
            resolve(true);
        }, 
        (err) => {
            resolve(false);
        });
    });
    p.then(success => callback(success))
        .catch(reason => callback(false));
}

export function initializeSession(resultHandler: (session: Session) => void, 
    errorHandler: (msg: string) => void, 
    expectFailure?: boolean) {
    sendFetch(expectFailure ? 'TRYGET' : 'GET', "/api/user", null, (user) => {
        const session: LoggedInSession = {
            isLoggedIn: true,
            user: user,
            api: new BackendApi(errorHandler),
        };
        resultHandler(session);
    }, 
    (err) => {
        resultHandler({isLoggedIn: false});
    });
}

export function verifyEmail(state: string, password: string, resultHandler: (session: Session) => void, errorHandler: (msg: string) => void) {
    sendFetch('POST', '/api/auth/verify', {state, password}, (data: any) => {
        initializeSession(resultHandler, errorHandler);
    }, errorHandler);
}

export function resetPassword(state: string, password: string, resultHandler: (session: Session) => void, errorHandler: (msg: string) => void) {
    sendFetch('POST', '/api/auth/reset', {state, password}, (data: any) => {
        initializeSession(resultHandler, errorHandler);
    }, errorHandler);
}

export function performSignIn(email: string, password: string, resultHandler: (session: Session) => void, errorHandler: (msg: string) => void) {
    sendFetch('POST', '/api/auth', {email, password}, (data: any) => {
        initializeSession(resultHandler, errorHandler);
    }, errorHandler);
}

export function sendForgotPassword(email: string, resultHandler: () => void, errorHandler: (err: string) => void) {
    sendFetch('POST', '/api/auth/forgot', {email: email}, (data: any) => {
        initializeSession(resultHandler, errorHandler);
    }, errorHandler);
}

function sendFetch(method: string, path: string, body: object | null, 
    successResponseFn: (data: any) => void, errorHandler?: (msg: string) => void) {
    const params: RequestInit = {
        credentials: 'include',
        method: method === 'TRYGET' ? 'GET' : method,
        headers: {},
        cache: 'no-store'
    };
    const csrfCookie = 'csrf-token'
    const cookies = decodeURIComponent(document.cookie).split(';')
    let headers: any = {}
    for(var i = 0; i <cookies.length; i++) {
        var c = cookies[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(csrfCookie) === 0) {
            headers['X-CSRF-TOKEN'] = c.substring(csrfCookie.length + 1, c.length)
            break
        }
    }
    if (body) {
        params['body'] = JSON.stringify(body)
        headers['Content-Type'] = 'application/json'
    }
    params.headers = headers
    fetch(Globals.API_URL + path, params)
    .then(function(response: Response) {
        if (response.status >= 200 && response.status <= 299) {
            response.json().then(function(data) {
                if (data['error']) {
                    if (method !== 'TRYGET') {
                        console.log(data['error'])
                        if (errorHandler != null) {
                            errorHandler(data['error']);
                        }
                    }
                } else if (successResponseFn) {
                    successResponseFn(data['data'])
                }
            })
            .catch(function(error) {
                console.log('Failed to read response json');
                console.log(error);
            })
        } else {
            if (method !== 'TRYGET') {
                console.log('Bad response from server ' + response.status + ': ' + response.statusText)
                if (errorHandler != null) {
                    if (response.status === 401) {
                        errorHandler('Unauthorized access, maybe sign-in expired? Reload and sign in again.')
                    } else {
                        errorHandler('Bad response from server: ' + response.status);
                    }
                }
            }
        }
    })
    .catch(function (error: any) {
        if (method !== 'TRYGET') {
            console.log('Failed to send request to ' + path)
            console.log(error)
            if (errorHandler != null) {
                errorHandler('An error occurred when communicating with the server: ' + error);
            }
        }
    })
}