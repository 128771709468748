import React, { useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import { ScriptApi } from "./Controllers";
import { Script, Scene } from "./Models";
import Board from './Board';
import { orderScenes } from './Outline';

function Settings(props: {
    isOpen: boolean;
    toggleOpen: () => void;
    script: Script;
    api: ScriptApi;
    boardFitScreen: boolean;
    setBoardFitScreen: (fit: boolean) => void;
}) {
  const [archiveOpen, setArchiveOpen] = useState(false);
  const numColumns = 3;
  const archive = props.script.board.archive || {};
  const [scenes, numRows] = useMemo(() => {
    const idOrderArray: [string, number][] = orderScenes(archive, props.script.board.acts)
      .flatMap((act: [string, number][]) => {
        return act;
      });
      const numRows = Math.ceil(idOrderArray.length / numColumns);
      const scenes: Array<[string, Scene]> = idOrderArray.map(([id, order]) => {
        return [id, {...archive[id], 
          x: (order % numColumns) * (1/numColumns), 
          y: Math.floor(order / numColumns) * (1/numRows)}];
      });
      let idScenes: Record<string, Scene> = {};
      for (const [id, scene] of scenes) {
        const key = id as string;
        idScenes[key] = scene;
      }
      return [idScenes, numRows];
  }, [{...archive}]);
  return <>
      <UncontrolledPopover hideArrow={true} trigger="legacy" placement="bottom" target="board-settings-toggle" 
      style={{fontSize: '0.9375rem'}}
      isOpen={props.isOpen} 
      toggle={props.toggleOpen}>
      <PopoverHeader>Settings</PopoverHeader>
      <PopoverBody>
      <form style={{minWidth: '250px'}}>
      <legend>View</legend>
      <fieldset className="form-group">
        <div className="form-check">
        <label className="form-check-label">
          <input type="checkbox" className="form-check-input"
            checked={props.boardFitScreen} 
          onChange={(ev) => { 
            props.setBoardFitScreen(ev.target.checked); 
          }}></input> Fit board to screen
        </label>
        </div>
      </fieldset>
      <legend>Script</legend>
      <div className="form-group d-flex align-items-center">
      <label style={{flexGrow: 1}}>{props.script.board.acts || 4} Acts</label> <button type="button" className="btn btn-primary btn-sm"
        onClick={() => {
            const newActsStr = window.prompt('Number of acts', '');
            if (newActsStr != null) {
              const newActs = parseInt(newActsStr);
              if (newActs && !isNaN(newActs) && newActs > 0 && newActs <= 50) {
                props.api.setNumberOfActs(newActs);
              } else {
                window.alert('Must be number between 1 and 50');
            }
          }
        }}
      >Change...</button>
      </div>
      <div className="form-group d-flex">
        <button type="button" className="btn btn-primary btn-sm" onClick={() => {
          setArchiveOpen(true);
        }}>Scene Archive...</button>
      </div>
    </form>
    </PopoverBody> 
    </UncontrolledPopover>
   
    <Modal size="xl" isOpen={archiveOpen} toggle={() => setArchiveOpen(!archiveOpen)} zIndex={10000}>
      <ModalHeader toggle={() => setArchiveOpen(!archiveOpen)} close={<h3 onClick={() => setArchiveOpen(!archiveOpen)}>&times;</h3>}>
      Scene Archive
      </ModalHeader>
      <ModalBody>
        <h5>Click scene to restore to script</h5>
        <div style={{width: '100%', height: '80vh', overflowY: 'scroll'}}>
          <Board 
            api={props.api}
            rowHeight={1.1}
            showActs={false}
            numColumns={numColumns}
            numRows={numRows}
            fitHeight={false}
            cards={scenes}
            onClickOverride={(id: string) => {
              props.api.restoreFromArchive(id, props.script.board.archive![id]);
            }}
          />
        </div>
      </ModalBody>
    </Modal>
  </>;
}

export default Settings;