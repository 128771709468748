import {IsInt, Length, ValidateNested} from "class-validator";

export interface Scene {
    id?: string,
    borderColor?: string,
    text?: string,
    contents?: any,
    details?: any,
    x: number,
    y: number
}

export interface PlanInfo {
    name: string;
    canUpgrade: boolean;
}

export interface UserInfo {
    name: string;
    email: string;
    isSocialLogin: boolean;
    scripts: Record<string, string>;
    newsConsent?: boolean;
    plan: PlanInfo;
}

const scriptTitleLength = 512;
const scriptDescriptionLength = 4096;
const sceneTextLength = 10000;

export class Board {
    acts?: number;

    @IsInt()
    nextSceneIndex: number; // necessary as cards can deleted

    @ValidateNested()
    scenes:  Record<string, Scene>;

    archive?: Record<string, Scene>;

    constructor(nextSceneIndex: number, scenes: Record<string, Scene>) {
        this.nextSceneIndex = nextSceneIndex;
        this.scenes = scenes;
    }
}

export class Script {
    @Length(0, scriptTitleLength)
    title: string;

    @Length(0, scriptDescriptionLength)
    description: string;

    @ValidateNested()
    board: Board;

    aidraft?: string;

    updateCount?: number;

    constructor(title: string, description: string, board: Board) {
        this.title = title;
        this.description = description;
        this.board = board;
    }
}

export interface Result {
    data?: any;
    error?: string;
}

export interface ScriptContext {
    maxScripts?: number;
    maxScenes?: number;
}

export class NewScriptCommand {
    constructor(public readonly initialData?: Script) {}
}